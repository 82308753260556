import PRIMARY from '../../static/images/managementApi.svg';
import SECONDARY from '../../static/images/dataApi.svg';
import DETECT from '../../static/images/detectApi.png';
import FINTECH from '../../static/images/fintechApi.png';
import ROUTES from '../docs-config/src';

export const APIReferenceItems = [
  {
    title: 'Data API',
    description: 'Insert and interact with data in your vaults.',
    image: SECONDARY,
    link: ROUTES.apiReference.record,
  },
  {
    title: 'Management API',
    description:
      'Control your account and schemas, including workspaces, vaults, users, and more.',
    image: PRIMARY,
    link: ROUTES.apiReference.management,
  },
  {
    title: 'Detect API',
    description: 'Detect and de-identify sensitive data in strings and files.',
    image: DETECT,
    link: ROUTES.apiReference.detect,
  },
  {
    title: 'FinTech API',
    description:
      'Manage your financial data, including network tokens and cryptograms.',
    image: FINTECH,
    link: ROUTES.apiReference.fintech,
  },
];
